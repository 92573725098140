import {ResumeTemplateTypes} from "./components/Resume/components/ResumeTemplates/ResumeTemplateTypes";

const config = {
    behavior: {
        useFixedNavBar: true,
    },

    templateType: ResumeTemplateTypes.STANDARD,

    name: "Diego Garcia",
    headline: "I love to build|solutions for companies",

    socialMediaUrl: {
       linkedin: "https://www.linkedin.com/in/devdiegogarcia",
       github: "https://github.com/diegomgarcia",
       instagram: "https://www.instagram.com/devdiegogarcia",
       x: "https://twitter.com/DevDiegoGarcia",
       facebook: "",
    },

    availabilityInfo: [
        { type: 'text', content: 'I am currently available for new opportunities. If you\'d like to discuss any sort of opportunity, feel free to ' },
        { type: 'link', content: 'contact me', href: 'mailto:%email%' }
    ],

    address: "Kingston, ON, Canada",
    email: "talkto@diegogarcia.ca",
    phone: "+1 (613) 532-4880",

    resume: {
      name: "",
      headline: "Experienced Full-Stack Software Engineer focused on solutions for companies",
      address: "",
      email: "",
      phone: "",
      website: "diegogarcia.ca",

      socialMediaUrl: {
        linkedin: "",
        github: "",
        instagram: "",
        x: "",
        facebook: "",
      },

      skills: [
          {
              area: "Engineering",
              skills: [
               "System Architecture",
               "Microservice Architecture",
               "OOP",
               "Restful API",
              ]
          },
          {
              area: "Languages",
              skills: [
                  "C#",
                  "GO",
                  "Flutter",
                  "Javascript",
                  "Delphi",
              ]
          },
          {
              area: "Backend Frameworks/Libraries",
              skills: [
                  ".NET",
                  ".NET Core",
                  "Entity Framework",
                  "Dapr"
              ]
          },
          {
              area: "Frontend Frameworks/Libraries",
              skills: [
                  "Vue.Js",
                  "React",
                  "Razor Pages",
                  "JQuery",
                  "Javascript",
                  "CSS",
                  "Tailwind CSS",
                  "Bootstrap",
                  "JQuery UI",
                  "Kendo UI",
              ]
          },
          {
              area: "Databases",
              skills: [
                  "Microsoft SQL",
                  "PostgreSQL",
              ]
          },

          {
              area: "Version Control",
              skills: [
                  "Git",
                  "Azure Repos",
                  "Mercurial",
                  "SVN"
              ]
          },
          {
              area: "Orchestration & Containerization",
              skills: [
                  "Kubernetes",
                  "Docker",
              ]
          },
          {
              area: "CI/CD",
              skills: [
                  "Azure Pipelines",
                  "Github Actions",
                  "Team City"
              ]
          },
          {
              area: "Project Management",
              skills: [
                  "Jira",
                  "Azure Boards"
              ]
          },
          {
              area: "Cloud & Hosting Services",
              skills: [
                  "Azure Cloud",
                  "Linode LDK",
              ]
          },
      ],

      experiences: [
          {
              description: "Software Engineer",
              company: "HQ Softwares",
              startDate: "2012",
              endDate: "Current",
              details: "At HQ Softwares, I am responsible for architecting and implementing software tailored to the customer’s needs. %br%This process involves:\n" +
                  "%bullet% Guiding the sales team during proposal elaboration, understanding project details, and creating proofs of concept.\n" +
                  "%bullet% Architecting systems, implementing the foundational version, writing unit tests, documenting it, and training the development team.\n" +
                  "%bullet% Setting up tooling, infrastructure, and deploying the solution to the customer.\n" +
                  "%endbullet%\n" +
                  "Each project has unique requirements, and it's my duty to analyze and determine the best approach for each one.\n" +
                  "%br%\n" +
                  "On a daily basis, I work with:\n" +
                  "%bullet% C# and .NET Core for backend development, using either a microservice or monolithic architecture with the MVC framework.\n" +
                  "%bullet% Vue.js with Pinia/Vite or Razor Pages with jQuery UI + Bootstrap for frontend development.\n" +
                  "%bullet% Microsoft SQL and PostgreSQL databases.\n" +
                  "%bullet% RabbitMQ for messaging between microservices.\n" +
                  "%bullet% Docker for deploying images to Kubernetes, using Azure Image Registry and AKS, or occasionally Linode LDK.\n" +
                  "%bullet% Git for source code management.\n" +
                  "%bullet% Azure DevOPS or Jira for task tracking via a scrum board and setting up CI/CD pipelines.\n" +

                  // "%bullet% C# and .NET Core for backend development, using either a microservice or monolithic architecture with the MVC framework. %bold%(16+ years exp.)%bold%\n" +
                  // "%bullet% Vue.js with Pinia/Vite %bold%(3+ years exp.)%bold% or Razor Pages with jQuery UI + Bootstrap for frontend development. %bold%(6+ years exp.)%bold%\n" +
                  // "%bullet% Microsoft SQL and PostgreSQL databases. %bold%(20+ years exp.)%bold%\n" +
                  // "%bullet% RabbitMQ for messaging between microservices. %bold%(3+ years exp.)%bold%\n" +
                  // "%bullet% Docker for deploying images to Kubernetes, using Azure Image Registry and AKS, or occasionally Linode LDK. %bold%(3+ years exp.)%bold%\n" +
                  // "%bullet% Git for source code management. %bold%(16+ years exp.)%bold%\n" +
                  // "%bullet% Azure DevOPS %bold%(3+ years exp.)%bold% or Jira for task tracking via a scrum board and setting up CI/CD pipelines. %bold%(5+ years exp.)%bold%\n" +
                  "%endbullet%\n" +
                  "My goal is to maintain high-quality standards by automating processes wherever possible, ensuring a positive customer experience, and continuously seeking optimizations to produce more efficient and reliable software.",
          },
          {
              description: "Software Development consultant",
              company: "TDS",
              startDate: "2010",
              endDate: "2012",
              details: "During my time at TDS, I was responsible for studying new technologies, formulating fresh content, documenting, and creating exercises to train developers from numerous software houses.%br%The classes I've mastered include:\n" +
                  "%bullet% Software development with Delphi.\n" +
                  "%bullet% Oriented Object Programming, Design Patterns and Test Driven Development.\n" +
                  "%bullet% Component Creation.\n" +
                  "%bullet% Multi-tier Architecture.\n" +
                  "%endbullet%\n" +
                  "My goal at TDS was not just to disseminate knowledge but also to continuously hone my own skills and ensure that every developer I trained was equipped with the most up-to-date practices in the industry.\n",

              //details: "During my time working for TDS, I was responsible to study new technologies, elaborate new content, document and create new exercises to training developers from several software house's located in Brazil. I have the opportunity to improve my skills thanks to the quantity of classes that I've prepared and mastered in TDS. Some of the classes that I've mastered includes, Software development with Delphi, Oriented Object Programming, Design Patterns, Test Driven Development, Component Creation, Multi tier Architecture and FinalBuilder automation.",
          },
          {
              description: "Software Developer",
              company: "Unit One",
              startDate: "2002",
              endDate: "2010",
              details: "At my previous position, I was responsible for designing, architecting frameworks, and developing custom software for clients as needed. %br% The technologies I primarily employed are:\n" +
                  "%bullet% C# with ASP .Net framework utilizing the MVC pattern and SQL Server.\n" +
                  "%bullet% Delphi with SQL Server for Desktop Backend applications.\n" +
                  "%endbullet%\n" +
                  "My principal goal was to diminish the intricacy of legacy software by creating a dedicated framework. This allowed for porting the legacy projects to newer technologies in significantly less time and enhanced the quality of the codebase. I employed techniques from design patterns and adhered to the SOLID and KISS principles.\n" +
                  "%br%\n" +
                  "The journey of framework development highlighted the need to enhance our database structures. This was done to boost its quality, making it more readable and maintainable. With this vision, I scripted software to migrate these structures automatically. The migration leveraged metadata and employed dictionary-based configurations.\n",

          }
      ],

      projects: [
          {
              name: "ERX",
              description: "Designed a multi-tenant ERP platform using a microservices architecture in .NET Core. Integrated Vue.js and Dart, with Dapr and RabbitMQ for microservices communication. Employed Kubernetes for deployment, secured with JWT and Ocelot API Gateway."
          },
          {
              name: "Q21 Dental Care",
              description: "Led the architectural design of a multi-tenant ERP tailored for dental care companies. Implemented a schema-based structure, integrated a REST API with a distinct API gateway for internal and mobile app access, and introduced real-time chat capabilities.",
          },
          {
              name: "Flutter Talks",
              description: "Architected a system for a major Flutter event in Brazil, integrating a user-friendly subscription portal with a real-time QR code validation app. Implemented a secure REST API ensuring seamless backend-mobile interactions."
          },
          {
              name: "Graphic Design Portal",
              description: "Developed a web portal for a company enabling clients to upload and manage graphic design projects. Facilitated tracking of execution, approval, and delivery processes."
          },
          {
              name: "Intermap for Divicenter",
              description: "Crafted 'Intermap' for event attendees to locate stands on digital totem via an infrared interface. Featured a map with routing, zoom, and pan, adopted by giants like Reed Exhibition and GL Events in the pre-touchscreen era."
          },
          {
              name: "MBM ERP System",
              description: "Engineered 'MBM', an ERP tailored for motoboy companies. Streamlined service order management, billing, provider payments, and customer notifications, while optimizing request sequencing."
          }
      ],

      education: [
          {
              title: "Associate degree in Software Analyses and Development",
              description: "",
              startDate: "2015",
              endDate: "2017",
          }
      ],

      languages: [
          {
             name: "English",
             proficiency: ""
          },
          {
              name: "Portuguese",
              proficiency: ""
          }
      ],
    },


    // Utility functions
    get firstName() {
        return this.name.split(' ')[0] || "";
    },

    get initials() {
        const names = this.name.trim().split(' ');
        const firstInitial = names[0] ? names[0][0] : "?";
        // Check if there is a last name, if not use the first name's initial if available
        const lastInitial = names[1] ? names[1][0] : firstInitial;
        return `${firstInitial}${lastInitial}`;
    },

    mapURL(url) {
        const baseURL = "https://maps.google.com/maps?";
        const query = encodeURIComponent(`${url}`);
        const params = [
            `width=100%`,
            `height=200`,
            `hl=en`,
            `q=${query}`,
            `ie=UTF8`,
            `t=`,
            `z=14`,
            `iwloc=B`,
            `output=embed`
        ].join('&');
        return `${baseURL}${params}`;
    },

    addressURL(url) {
        const baseURL = "https://www.google.com/maps/place/";
        const query = encodeURIComponent(url);
        return `${baseURL}${query}`;
    },

    displayURL(url) {
        return url.replace("https://", "").replace("www.", "");
    }
};



export default config;
